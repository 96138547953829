.notification-settings {
  width: 40%;
  text-align: center;
  margin: auto;
}

.notification-settings h5 {
  margin-top: 30px;
  margin-bottom: 25px;
}

.notification-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notification-option input {
  width: 15px;
  height: 15px;
}

.save-changes {
  margin: 30px auto;
  width: 70%;
}