.MuiDataGrid-cellLeft > * {
  direction: rtl !important;
  left: auto !important;
  width: 100%;
  top: 3px;
  right: 70px !important;
}

.Rectangle-54 {
  border-radius: 4px;
  border: solid 0.5px #dde2e6;
  background-color: #f9fafa;
}
