.expense-filter-wraper {
  background-color: #fff;
  width: 100vw;
  position: relative;
  /* left: calc(-50vw + 50%); */
  left: 50%;
  right: 50%;
  margin-inline: -50vw;
  margin-bottom: 50px;
  height: min-content;
}

.expense-filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  margin-inline: auto;
  padding: 20px;
}

.expense-filter-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.expense-filter-icon {
  font-size: 1.5rem;
  color: #0BB78A;
  margin-inline-end: 5px;
}

.expense-filter-actions > h4 {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0px;
}

.expense-filter-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-inline-start: 20px;
}

.expense-filter-item label {
  font-size: 20px;
  margin-bottom: 0px;
  margin-inline-end: 10px;
}


.expense-filter-export {
  width: 20%;
}