.team-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.team-filters-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.team-filter {
  width: 150px;
  margin: auto 5px;
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.team-filter h6 {
  margin-bottom: 0px;
}

.team-filter select {
  border: 0px;
  cursor: pointer;
  width: fit-content;
}

.team-filter select:focus {
  border: 0px;
}

.search-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.search-group button {
  margin-top: 0px;
  width: 120px;
}

.search-clear {
  margin: auto 10px;
  display: flex;
  flex-direction: row;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.search-clear input,
.search-clear input:active,
.search-clear input:focus-within,
.search-clear input:focus-visible,
.search-clear input:focus {
  border: 0px;
  outline: none;
  width: 150px;
}

.team-pagination {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.team-pagination p {
  margin: 5px;
  word-spacing: 5px;
}

.pagination-icon {
  font-size: 1.5rem;
  font-weight: bold;
  margin: auto 10px;
  cursor: pointer;
}

.team-user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* .team-user img {
  width: 35px;
  margin: auto 5px;
} */

.MuiTableCell-root {
  padding: 14px 10px !important;
}