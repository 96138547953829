.categoriesTable {
  height: 400px;
  width: 80%;
  margin: 30px auto auto auto;
}

.category-title {
  max-width: 1280px;
  margin-inline: auto;
  margin-top: 20px;
  font-weight: bold;
}

.categories-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
}

.categories-container h1 {
  width: 100%;
  text-align: center;
}

.category-card {
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #F3F4F6;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 20px;
  margin-inline: 1%;
}

.category-card > h4 {
  font-size: 20px;
  margin-bottom: 0px;
}

.cat-card-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cat-card-actions img {
  margin-inline: 10px;
  cursor: pointer;
}

.cat-card-actions span {
  font-size: 25px;
  color: #9CA3AF;
}

.cat-modal {
  padding: 20px;
  position: relative;
}

.cat-modal h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 30px;
}

.add-category-btn {
  width: 60%;
  margin-inline: 20%;
  margin-top: 50px;
}