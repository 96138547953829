.success i {
  background: linear-gradient(90deg, #009b54 0%, #00a85d 100%);
  padding: 20px;
  font-size: 70px;
  color: #ffffff;
  border-radius: 50%;
}

.success h2 {
  color: #1d2b36;
  margin: 30px 0;
  line-height: 40px;
}

.success p {
  color: #314351;
  margin: auto;
  margin-bottom: 20px;
  width: 70%;
}

.success span {
  color: #1d2b36;
}

.success .green {
  color: #39ac6e;
}

.success .btnSmall {
  margin-top: 30px;
  height: 40px;
  line-height: 25px;
  display: block;
}

.setteled-checks {
  width: fit-content;
  margin: 20px auto;
}

.bulgy-radios {
  width: 38rem;
  background: #fff;
  border-radius: 1rem;
}
.bulgy-radios label {
  display: block;
  position: relative;
  height: 1em;
  padding-left: 2rem;
  margin-bottom: 1.75rem;
  cursor: pointer;
  font-size: 2rem;
  user-select: none;
  color: #555;
  letter-spacing: 1px;
  align-content: center;
  justify-items: center;
}
.bulgy-radios label:hover input:not(:checked) ~ .radio {
  opacity: 0.8;
}
.bulgy-radios .label {
  display: flex;
  align-items: center;
  padding-right: 3rem;
}
.bulgy-radios .label span {
  line-height: 1em;
}
.bulgy-radios input {
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
  left: -2000px;
}
.bulgy-radios input:checked ~ .radio {
  background-color: #0ac07d;
  transition: background 0.3s;
}
.bulgy-radios input:checked ~ .radio::after {
  opacity: 1;
}
.bulgy-radios input:checked ~ .label {
  color: #0bae72;
}
.bulgy-radios input:checked ~ .label span {
  animation: bulge 0.5s forwards;
}
.bulgy-radios input:checked ~ .label span:nth-child(1) {
  animation-delay: 0.025s;
}
.bulgy-radios input:checked ~ .label span:nth-child(2) {
  animation-delay: 0.05s;
}
.bulgy-radios input:checked ~ .label span:nth-child(3) {
  animation-delay: 0.075s;
}
.bulgy-radios input:checked ~ .label span:nth-child(4) {
  animation-delay: 0.1s;
}
.bulgy-radios input:checked ~ .label span:nth-child(5) {
  animation-delay: 0.125s;
}
.bulgy-radios input:checked ~ .label span:nth-child(6) {
  animation-delay: 0.15s;
}
.bulgy-radios input:checked ~ .label span:nth-child(7) {
  animation-delay: 0.175s;
}
.bulgy-radios input:checked ~ .label span:nth-child(8) {
  animation-delay: 0.2s;
}
.bulgy-radios input:checked ~ .label span:nth-child(9) {
  animation-delay: 0.225s;
}
.bulgy-radios input:checked ~ .label span:nth-child(10) {
  animation-delay: 0.25s;
}
.bulgy-radios input:checked ~ .label span:nth-child(11) {
  animation-delay: 0.275s;
}
.bulgy-radios input:checked ~ .label span:nth-child(12) {
  animation-delay: 0.3s;
}
.bulgy-radios input:checked ~ .label span:nth-child(13) {
  animation-delay: 0.325s;
}
.bulgy-radios input:checked ~ .label span:nth-child(14) {
  animation-delay: 0.35s;
}
.bulgy-radios input:checked ~ .label span:nth-child(15) {
  animation-delay: 0.375s;
}
.bulgy-radios input:checked ~ .label span:nth-child(16) {
  animation-delay: 0.4s;
}
.bulgy-radios input:checked ~ .label span:nth-child(17) {
  animation-delay: 0.425s;
}
.bulgy-radios input:checked ~ .label span:nth-child(18) {
  animation-delay: 0.45s;
}
.bulgy-radios input:checked ~ .label span:nth-child(19) {
  animation-delay: 0.475s;
}
.radio {
  position: absolute;
  top: 0.2rem;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background: #c9ded6;
  border-radius: 50%;
}
.radio::after {
  content: '';
  position: absolute;
  opacity: 0;
  top: 0.5rem;
  left: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #fff;
}
@keyframes bulge {
  50% {
    transform: rotate(4deg);
    font-size: 1.5em;
    font-weight: bold;
  }
  100% {
    transform: rotate(0);
    font-size: 1em;
    font-weight: bold;
  }
}

.supplierBankForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
