.pending-expense .item .request .request-user-image {
  width: 50px;
  overflow: hidden;
  margin: auto;
}

.pending-expense .item .request-user-image img {
  width: 100%;
}

.pending-expense .item .request .request-image {
  overflow: hidden;
  width: auto;
  max-height: 50px;
  margin-left: 10px;
}

.pending-expense .item .request .request-image img {
  max-height: 50px;
  cursor: pointer;
}

.pending-expense .item .request .request-images {
  margin-left: 30px;
  vertical-align: middle;
}

.modal-image {
  width: 100%;
  height: auto !important;
  max-height: 500px;

  text-align: center !important;
}

.modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-head {
    text-align: center;
}

.head {
    font-size: larger;
}

.note-option {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
    margin: 0 auto 10px auto
}

.note-option input {
    margin: auto 10px;
}

.note-option p {
    margin-bottom: 0;
    text-align: start;
}

.note-3 {
    margin: auto;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.note-3 p {
    margin-bottom: 0;
    width: 100%;
    text-align: start;
}

.note-3 .blue-warning {
    width: 100%;
}

/* .warning-head {
    margin-bottom: 20px;
} */

.no-receipt {
    height: 100%;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.no-receipt img {
    width: 60%;
    margin: auto;
}

.no-receipt .red-warning {
    box-sizing: border-box;
    margin: 0;
}

.req-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.modal-content {
    border-radius: 0.8rem;
}

.label {
    font-size: medium;
    color: #1d2b36;
    font-weight: bold;
}

.download-icon {
    padding: 2px;
    background-color: #009b54;
    color: white;
    border-radius: 50%;
}

.reconsileResBtn {
  width: 60%;
  margin-left: 20%;
}