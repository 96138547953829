.login-page-wrapper {
  height: 100vh;
  width: 100%;
  background: #37bd7d;
}

.login-page {
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* DUMMY CLASSES */
.section-title {
  color: #1f2937;
  font-size: 2.3rem;
  text-align: center;
  font-weight: 700;
}

.form-line-full {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.form-element-full label,
.form-element-half label {
  font-weight: 400;
  font-size: 20px;
}

.form-element-full input,
.form-element-half input {
  height: 42px;
  font-size: 1.2rem;
}

.form-element-full {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
}

.form-element-half {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.form-err {
  color: firebrick;
  margin-top: 5px;
}

.ant-input-affix-wrapper-focused,
.ant-input:focus {
  border-color: #0bb78a !important;
  box-shadow: 0 0 0 2px rgba(11, 183, 138, 0.2) !important;
}

.ant-input-affix-wrapper {
  padding: 0px !important;
}

.ant-input-password {
  padding-inline: 11px !important;
}
