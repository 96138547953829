.billing-plan {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-cards-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  width: 85%;
  margin: auto;
}

.billing-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.current-membership {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  margin-bottom: 30px;
}

.membership-dateils p {
  margin-bottom: 0px;
}

.membership-price {
  width: 20%;
  text-align: end;
  margin-bottom: 0px;
}

.billing-details .month {
  font-size: 14px;
  color: #989898;
}

.plan-control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.plan-control-element {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 31%;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 10px;
}

.control-element-title {
  margin-bottom: 0px;
  color: #90929c !important;
}

.control-element-content {
  margin-bottom: 0px;
  font-weight: bold;
  color: black !important;
}

.control-element-action {
  margin-bottom: 0px;
  color: #1292ee;
  cursor: pointer;
}

#BillingPlan-SubTitle2 {
  width: 70%;
  font-weight: bold;
  text-align: center;
}
