.profile-settings {
  width: 65%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile-setting-header {
  width: 100%;
  text-align: center;
  flex-wrap: wrap;
}

.profilePicture-side {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.update-avatar {
  color: white;
  background-color: #000;
  width: 100%;
  margin: 30px auto;
}

.profile-info {
  width: 48%;
}

.change-hint {
  color: #748494;
  margin-top: 10px;
  margin-bottom: 0px;
}
