.sidebar {
  overflow: hidden;
  min-height: 100vh;
}

.side-nav {
  background: black;
  padding-top: 50px;
  margin-bottom: -99999px;
  padding-bottom: 99999px;
  text-align: center !important;
}
.side-nav h2 {
  color: white;
  font-weight: bold;
  margin-bottom: 120px;
}
.side-nav h2 .green-color {
  color: #009159;
}
.side-nav h1 {
  color: white;
  font-weight: bold;
  margin-bottom: 40px;
}
.side-nav img {
  margin-bottom: 30px;
}
.side-nav ul {
  list-style: none;
  margin-bottom: 100px;
}
.side-nav ul li {
  margin-bottom: 20px;
}

.side-nav ul li span {
  color: #5a5a5a;
  font-weight: bold;
  transition: all 0.5s ease;
  cursor: default;
}

.side-nav ul li .active {
  color: white;
}
.side-nav .question {
  color: #5a5a5a;
}
.side-nav .contact-us {
  font-weight: bold;
  color: white;
}
