.pending-expense .item .details {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 20px;
    position: relative;
    z-index: 1;
}

.pending-expense .item .details.active {
    border-radius: 15px 15px 0 0;
}

.pending-expense .item .details .requests {
    background-color: #f0ad17;
    color: #ffffff;
    border-radius: 100%;
    padding: 15px;
}

.pending-expense .item .details .requests.active {
    background-color: #1292ee;
}

.pending-expense .item .details .openRequests {
    cursor: pointer;
}

.pending-expense .item .hidden {
    background: rgba(255,255,255, 0.6);
    width: 92%;
    height: 50px;
    position: relative;
    top: -40px;
    border-radius: 15px;
    margin: auto;
    z-index: 0;
}

.pending-expense .item .hidden2 {
    z-index: 0;
    background: rgba(255,255,255, 0.5);
    top: -75px;
    width: 80%;
}

.pending-expense .item .item-requests {
    background-color: #ffffff;
}

.pending-expense .item .item-requests .request {
    border-top: 1px solid #dddddd;
    padding: 15px 20px;
}