.welcome {
    /* width: 100%; */
    background-color: black;
    background-image: url(../../../../assets/images/orbits.png);
    background-position: 450px center;
    background-size: 60%;
    background-repeat: no-repeat;
}

.welcome .header {
    height: 90vh;
}

.welcome h1 {
    color: white;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
}
.welcome p {
    color: white;
    margin-bottom: 50px;
}
.welcome .register-btn {
    display: block;
    color: white;
    background: #009159;
    width: 80%;
    margin-bottom: 20px;
    font-weight: bold;
    transition: all .5s ease;
}
.welcome .register-btn:hover {
    color: #009159;
    background: none;
    border: 1px solid #009159;
}
.welcome .login-btn {
    display: block;
    color: black;
    background: white;
    width: 80%;
    margin-bottom: 50px;
    font-weight: bold;
    transition: all .5s ease;
}
.welcome .login-btn:hover {
    color: white;
    background: none;
    border: 1px solid white;
}

.logos-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.trusted-logos {
    height: 60px;
    margin-right: 30px;
}