.plan-card {
  width: 31%;
  border-radius: 10px;
  padding: 25px 20px;
  margin: 20px 1%;
  text-align: start;
  background-color: #f1f3f5;
  position: relative;
}

.plan-name {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: inherit;
}

.plan-card .month {
  font-size: 14px;
  color: #989898;
}

.plan-card .benifit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.most-popular {
  width: fit-content;
  flex-direction: row;
  align-items: center;
  background-color: #f57f17;
  color: #fff;
  padding: 3px 7px;
  border-radius: 20px;
  font-size: 11px;
  margin-inline-start: 15px;
  margin-bottom: 0px;
}

.most-popular p {
  margin-bottom: 0px;
}

.name-save {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5em;
}
