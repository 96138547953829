.reimbursement ul {
  padding-left: 10px;
}

.reimbursement ul li {
  margin-bottom: 30px;
  line-height: 15px;
  list-style: none;
  align-self: flex-start;
}

.reimbursement ul li img {
  margin-right: 15px;
  display: inline-block;
}

.reimbursement ul li span {
  display: inline-block;
  width: 80%;
  line-height: 20px;
}

/* .reimbursement ul li:nth-of-type(2) {
  margin-top: 0px;
  margin-bottom: 20px;
} */

.reimbursement .reimbursement-image img {
  border-radius: 10px;
  width: 100%;
}

/* .reimbursement {
  min-height: 520px;
} */

.reimbursement a {
  width: 50%;
  height: 50px;
  line-height: 35px;
  margin: auto;
  position: relative;
}

.spanStep {
  width: 90% !important;
}

.pop-up {
    height: fit-content;
}

.pop-up h1 {
    font-size: x-large;
}

.pop-up h5 {
    font-size: small;
}

.pop-up li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.buttonsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}