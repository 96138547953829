.page {
  padding-top: 80px;
  padding-left: 100px;
  padding-right: 100px;
}
.page h1 {
  font-weight: bold;
  color: black;
  margin-bottom: 40px;
}
.page p {
  color: #2e2e2e;
}
.page .alertPage {
  border: 1px solid #528b74;
  background: #f4fff9;
  margin-bottom: 30px;
}
.page .alertPage h6 {
  color: #467c60;
  font-weight: bold;
  margin-bottom: 15px;
}
.page .alertPage p {
  color: #59a584;
}
.page .forms label {
  color: #333;
  margin-bottom: 15px;
}
.page .forms .inputComp {
  margin-bottom: 30px;
}

.page .forms input::placeholder {
  color: #b9bbc2 !important;
}

.btnPrimaryHovered:hover,
.btnPrimary {
  color: white;
  background: #009b54;
  font-weight: bold;
  width: 100%;
  margin-top: 10px;
  transition: all 0.5s ease;
}

.btnGray {
  color: white;
  background: #405261;
  font-weight: bold;
  width: 100%;
  transition: all 0.5s ease;
  border: 1px solid #405261;
}

.btnGray:hover {
  color: #405261;
  background: white;
  font-weight: bold;
  width: 100%;
  border: 1px solid #405261;
  transition: all 0.5s ease;
}

.btnSec {
  color: white;
  background: #b12300;
  font-weight: bold;
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
  transition: all 0.5s ease;
}

.btnThrd {
    color: white;
    background: goldenrod;
    font-weight: bold;
    width: 100%;
    margin-top: 10px;
    transition: all 0.5s ease;
  }

.btnSkip {
  color: #009b54;
  background: #ffffff;
  font-weight: bold;
  width: 100%;
  border: 1px solid #009b54;

  margin-top: 10px;
  transition: all 0.5s ease;
}

.btnSkip:hover {
  color: #fff !important;
  background: #009b54 !important;
}

.btnPrimaryLg {
  height: 65px;
  line-height: 50px;
}

.btnPrimaryHovered {
  font-weight: bold;
  width: 100%;
  margin-top: 10px;
  transition: all 0.5s ease;
}

.btnPrimaryHovered,
.btnPrimary:hover {
  color: #009b54;
  border: 1px solid #009b54;
  background: none;
}

.btnSec:hover {
  color: #b12300;
  border: 1px solid #b12300;
  background: none;
}

.btnThrd:hover {
    color: goldenrod;
    border: 1px solid goldenrod;
    background: none;
  }

.btnPrimary:active {
  color: #009b54;
  border: 1px solid #009b54;
  background: none;
}

.btnSec:active {
  color: #b12300;
  border: 1px solid #b12300;
  background: none;
}

.btnSec:active {
    color: goldenrod;
    border: 1px solid goldenrod;
    background: none;
  }

.nav-item:active {
  color: #009b54;
  border: 1px solid #009b54;
  background: none;
}

.nav-link_:active {
  color: #009b54 !important;
  border: 1px solid #009b54 !important;
  background: none !important;
}

.btnSecondary {
  color: #009b54;
  border: 1px solid #009b54;
  font-weight: bold;
  width: 100%;
  margin-top: 10px;
  transition: all 0.5s ease;
}

.btnSecondaryLg {
  height: 65px;
  line-height: 50px;
}

.btnSecondary:hover {
  color: #ffffff;
  background-color: #009b54 !important;
  background: none;
}

.btnLink {
  color: #000000;
}

.btnLink:hover {
  color: #000000;
  text-decoration: none;
}

@media (max-width: 1000px) {
  .page .alert {
    width: 100%;
  }
}

.alert-float {
  position: absolute;
  top: 100px;
  right: 100px;
  width: 25%;
}
