.adminRoles {
    padding-top: 80px;
    padding-left: 100px;
}
.adminRoles h1 {
    font-weight: bold;
    font-size: 28px;
    color: black;
    margin-bottom: 40px;
}
.adminRoles .caption {
    color: #414141;
    font-size: 16px;
    margin-bottom: 50px;
    margin-top: -20px;
}
.adminRoles .first-row {
    margin-bottom: 40px;
}
.adminRoles .first-row h4 {
    font-size: 27px;
    font-weight: bold;
    color: black;
    margin-left: -20px;
}
.adminRoles .first-row p {
    margin-left: -20px;
    color: #505050;
    font-size: 16px;
}
.adminRoles .invitation {
    color: #505050;
    font-weight: bold;
    font-size: 14px;
}
.adminRoles .second-row input {
    height: 50px;
}

.adminRoles .second-row .btnInvite {
    color: white;
    background: black;
    height: 50px;
    font-size: 18px;
    line-height: 33px;
    transition: 0.3s ease-in-out;
}

.adminRoles .second-row .btnInvite:hover{
    background-color: #009159;
}
.adminRoles .second-row .check {
    margin-bottom: 7px;
}
.adminRoles .second-row .fa-check {
    color: #009159;
}
.adminRoles .second-row .green-color {
    color: #009159;
}
/* end admin roles */
