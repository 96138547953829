.teamReqContainer {
  position: relative;
  max-width: 1280px;
  margin: auto;
}

.expenseFilter {
  z-index: 10;
  padding-inline: 20px;
}
