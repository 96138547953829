.nav {
  max-width: 470px;
  margin: auto;
  margin-top: 2em;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}

.link {
  background: #ffffff;
  padding: 0.8em 1.5em;
  color: var(--clr-secondary);
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  flex-grow: 1;
}

.link:not(:last-child) {
  margin-right: 3px;
}

.link_active {
  background-color: var(--clr-primary);
  color: #f9fafb;
}

.link:hover {
  color: var(--clr-primary);
}
.link_active:hover {
  color: var(--bg-clr);
}
