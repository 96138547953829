.notifications-menu {
  max-width: 350px;
  max-height: 50vh;
  overflow-y: auto;
  background-color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  border: solid 0.5px #c5ced6;
  box-sizing: border-box;
}

.notifications-menu::-webkit-scrollbar {
  width: 10px;
  margin: 2px;
}

.notifications-menu::-webkit-scrollbar-track {
  background-color: rgb(219, 219, 219);
  border-radius: 5px;
  margin: 2px;
}

.notifications-menu::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.notifications-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.mark-read {
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: #1292ee;
}

.mark-read:hover {
  text-decoration: underline;
}

.single-notification {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.notification-avatar {
  margin: 5px 10px;
}

.notification {
  cursor: pointer;
}

.notifi-descrp {
  color: #314351;
  margin-bottom: 0px;
}

.notification-submition {
  color: #748494;
  font-size: 0.7rem;
  margin-bottom: 0px;
}
