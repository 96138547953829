.account-settings {
  width: 40%;
  text-align: center;
  margin: auto;
}

.account-settings-form {
  margin-bottom: 80px;
}

.mail-disables {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.verify-shield {
  font-size: 1.2rem;
  color: #39ac6e;
}

.otp-cell {
  margin: 20px 10px;
}

.counter-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ant-statistic-content {
  font-size: inherit !important;
}

.verify-button {
  width: 50%;
}

.phone-section {
  position: relative;
}

.phone-verified {
  color: #39ac6e;
  font-size: large;
  position: absolute;
}

.phone-delete {
  color: red;
  font-size: x-large;
  position: absolute;
  cursor: pointer;
}
