.navbar-background {
  background-color: #1d2b36;
  height: 70px;
}

.mainNavbar {
  height: 100%;
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.left-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  height: 100%;
}

.left-nav img {
  height: 45px;
  width: auto;
  margin: auto 20px;
}

.ant-dropdown-menu {
  border-radius: 7px !important;
}

.ant-divider-horizontal {
  margin: 10px 0px;
}

.nav-menus {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-menus a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-tab,
.nav-tab:hover,
.nav-tab:visited {
  color: #fff !important;
  margin: auto 10px;
  letter-spacing: normal;
  box-sizing: border-box;
}

.active-nav-tab,
.active-nav-tab:hover,
.active-nav-tab:visited {
  margin: auto 10px;
  letter-spacing: normal;
  color: #56c288 !important;
}

.nav-menu-item {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.icon-wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px
}

.nav-menu-icon {
  margin: auto;
  font-size: 16px;
}

.right-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.nav-action {
  font-size: 22px;
  color: #fff;
  cursor: pointer;
  margin: auto 10px;
}

.mobile-menu {
  display: none;
}

.ant-menu-submenu-title,
.ant-menu-submenu,
.ant-menu-sub,
.ant-menu-item,
.ant-menu-submenu-title,
.ant-drawer-wrapper-body {
  background-color: #ffffff !important;
}