.request-inadvance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 30px;
    margin: 20px 40px;
}

.inadvance-form {
    width: 46%;
    display: flex;
    flex-direction: column;
}

.form-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 30px 0px;
}

.row-element {
    width: 48%;
    display: flex;
    flex-direction: column;
}

.inadvance-form label {
    color: #333;
}

.inadvance-form input,
.inadvance-form select {
    border-radius: 8px;
    height: 50px;
    padding-left: 20px;
}

.spliter {
    border: 1px solid #ddd;
}

.reconcile-upload {
    width: 46%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.red-warning {
    width: 70%;
    background-color: #fff6e3;
    padding: 8px;
    border-radius: 10px;
    color: #253642;
    margin: 15px;
    font-size: small;
    font-weight: bold;
}

.red-warning svg {
    color: #ffc107;
}

.blue-warning {
    width: 70%;
    background-color: #f3fbff;
    padding: 8px;
    border-radius: 10px;
    color: #253642;
    margin: 15px;
    font-size: small;
    font-weight: bold;
}

.blue-warning svg {
    color: #1292ee;
}

.warning-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* line-height: 10px; */
    margin-bottom: 10px;
}

.warning-head p {
    margin: 5px;
}
