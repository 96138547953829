.ant-btn {
  height: auto;
  padding: 10px;
}

.ant-picker {
  padding: 8px;
  border-radius: 4px;
}

.ant-btn:disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
  transform: none;
}

/*  ant table */
.payroll .ant-table-thead > tr > th {
  background-color: #56c288;
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.ant-table-container,
.payroll .ant-table-thead > tr > th:first-of-type {
  border-start-start-radius: 10px !important;
}

.ant-table-container,
.payroll .ant-table-thead > tr > th:last-of-type {
  border-start-end-radius: 10px !important;
}

.table-row-colored {
  background: rgba(70, 127, 142, 0.05);
}

/* Scroll bar  */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background: #e5e5e5;
}
.container {
  padding: 2.5em 3.5em;
}
