.title-h1 {
  margin: 20px auto;
  font-weight: bold;
  font-size: 2rem;
}

.page-content {
  padding: 0 40px;
}

.requests-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btn-new-req {
  width: 200px !important;
}

.filter {
  display: flex;
  flex-direction: row;
}

.filter .tab {
  margin: 0 30px 20px 30px;
}

.filter .tab:hover {
  cursor: pointer;
}

.filter .active-tab {
  color: #39ac6e;
}

.filter-nav-backgroung {
  height: 50px;
  background-color: #fafafa;
  margin-left: -15px;
  margin-right: -15px;
}

.filter-nav {
  background-color: #fafafa;
  max-width: 1280px;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.filter-nav span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.filter-nav h6 {
  margin: 0px;
  color: #748494;
  text-transform: uppercase;
}

.bradCrumbIcon {
  color: #748494;
}

.filter-element,
.filter-element:hover {
  /* margin: auto 0px auto 20px; */
  margin-inline-start: 20px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  color: #93a1b0 !important;
}

.filter-element:focus {
  box-shadow: none !important;
  outline: none;
}

.active-filter-element,
.active-filter-element:hover {
  color: #1d2b36 !important;
}

.MuiDataGrid-cellLeft > * {
  direction: rtl !important;
  left: auto !important;
  width: 100%;
  top: 3px;
  right: 70px !important;
}

.MuiDataGrid-columnsContainer {
  border-radius: 4px;
  background-color: #556575;
}

.MuiDataGrid-colCellTitle {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 0.18px;
  color: #ffffff;
  margin: auto;
}

.MuiDataGrid-root .MuiDataGrid-colCellTitleContainer {
  align-items: center;
  text-align: center;
}

.MuiDataGrid-root .MuiDataGrid-colCell {
  margin: auto;
}

.MuiDataGrid-colCellWrapper {
  margin: auto;
}

.request-review {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow-y: hidden;
  position: relative;
}

.req-doc {
  width: 45%;
  height: 100%;
}

.carousel-root {
  height: 100%;
}

.carousel .slide img {
  /* width: auto; */
  height: 390px;
  /* overflow: hidden; */
}

.carousel .thumbs {
  display: none;
}

.carousel .thumbs-wrapper {
  display: none;
}

.carousel-item,
.carousel-item img {
  height: 400px;
}

.req-info {
  width: 100%;
  height: 100%;
}

.req-info h2 {
  font-size: x-large;
  font-weight: bold;
  margin-bottom: 30px;
}

.req-info .req-status {
  padding: 10px;
  border-radius: 7px;
  width: fit-content;
}

.req-status {
  font-size: large;
  /* margin: 0 auto 16px auto; */
}

.data-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.data-element {
  width: 49%;
}

.paid-for {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.req-info .label {
  font-size: medium;
  color: #1d2b36;
  font-weight: bold;
}

.req-info .value {
  font-size: medium;
  color: #405261;
}

.MuiDataGrid-root .MuiDataGrid-row:hover {
  cursor: pointer;
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}

.close-icon:hover {
  cursor: pointer;
}

.make-new {
  color: #1292ee;
  font-size: small;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0px;
}

.make-new:hover {
  cursor: pointer;
  text-decoration: underline;
}
