.gettingStarted {
  background-color: white;
}

.gettingStarted .hand-emoji {
  transform: rotate(-35deg);
  color: #f0d800;
}

.arLabel {
  width: 100%;
  text-align: right;
}

.pass-requirements,
.passShowHide {
  position: absolute;
  top: 48px;
  cursor: pointer;
  height: fit-content;
}
