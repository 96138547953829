.plan-upgrade {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.upgrade-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.payment-details {
  width: 59%;
  padding: 20px;
  margin-top: 40px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.addCard-modal label,
.payment-details label {
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: fit-content;
  font-weight: bold;
}

.updgrade-icon {
  font-size: 25px;
  margin-bottom: 5px;
}

.billing-switch {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.plans-to-upgrade {
  width: 40%;
  padding: 20px;
  margin-top: 40px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.addCard-modal .MuiRadio-colorPrimary.Mui-checked,
.plans-to-upgrade .MuiRadio-colorPrimary.Mui-checked,
.payment-details .MuiRadio-colorPrimary.Mui-checked {
  color: #56c288 !important;
}

.payment-details .ant-divider {
  border-top: 2px solid rgba(0, 0, 0, 0.06) !important;
}

.upgrade-option-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.upgrade-option {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 11px;
}
