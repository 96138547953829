.expense-chart-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.date-export {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.date-export button {
  margin: 0px 10px;
}

.react-datepicker__input-container input {
  border-radius: 5px;
  cursor: pointer;
  /* border-color: #39ac6e; */
  /* outline: none; */
}
