.checkMail {
  background: black;
  padding: 40px 0;
  min-height: 100vh;
}
.checkMail h2 {
  color: white;
  font-weight: bold;
  margin-bottom: 40px;
  font-size: 35px;
}
.checkMail h2 .green-color {
  color: #009159;
}
.checkMail img {
  margin-bottom: 60px;
}
.checkMail h1 {
  color: white;
  font-weight: bold;
  font-size: 45px;
  margin-bottom: 60px;
}
.checkMail p {
  display: inline;
  color: #828282;
  font-size: 16px;
}
.checkMail p a {
  display: inline;
  color: #828282;
  font-weight: bold;
  font-size: 16px;
  margin-top: 9px;
}
.checkMail p a:hover {
  color: #828282;
}
