.request label {
  color: #333;
  margin-bottom: 5px;
}

.request .inputComp {
  margin-bottom: 15px;
}

.request input,
.request select {
  border-radius: 8px;
  height: 50px;
  padding-left: 20px;
}

.request input::placeholder {
  color: #b9bbc2 !important;
}

.remove-image:hover {
    cursor: pointer;
}

.currency-flag {
    width: 30px;
    height: 20px;
}