.round_ {
  display: inline-block;
  margin: auto;
}

.round_ label {
  /* background-color: rgba(0, 0, 0, 0.89); */
  background-image: linear-gradient(to top, #405261, #253642);
  color: #fff;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  height: 32px;
  width: 32px;
  margin: auto;
  line-height: 26px;
}

.round_ label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round_ input[type="checkbox"] {
  visibility: hidden;
}

.round_ input[type="checkbox"]:checked + label {
  background-image: linear-gradient(to top, #2caa66, #75d09f);
  border-color: #66bb6a;
}

.round_ input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.supplier-req-rev,
.supplier-user-actions {
  width: 60%;
  margin: auto;
}
