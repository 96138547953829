.company-profile {
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-tel-input {
  direction: ltr;
}

.react-tel-input input {
  width: 100% !important;
}

.ownership {
  margin: 60px auto 25px auto;
  text-align: center;
}