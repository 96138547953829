.dashboard-pages {
  background-color: #f1f3f5;
}

body {
  background-color: #f1f3f5;
}

.mainPage {
  max-width: 1280px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  margin: 20px auto;
}

.mainPage .mainNav {
  list-style: none;
  margin: 0%;
  padding: 0;
}

.mainPage .mainNav li {
  float: left;
  font-size: 14px;
  color: rgb(88, 88, 88);
  cursor: pointer;
}

.mainPage .mainNav li span.break {
  color: rgb(170, 170, 170);
  cursor: default;
  margin: 0 5px;
}

.infoSupplier {
  background-color: '#60cf';
  border-radius: 10;
  border-width: 1;
  border-color: '#fff';
}

.Path-17805 {
  border: solid 0.5px #c5ced6;
  background-color: #f1f3f5;
}
