.uploadImages .image-upload > input {
  display: none;
}
.uploadImages .image-upload_ > input {
  display: none;
}

.image-upload .upload-text {
    color: #1292ee;
}

.uploadImages .image-upload {
  background-color: #f9fafa;
  border: 2px dashed rgba(197, 206, 214, 0.53);
  border-radius: 10px;
  padding: 63px;
}
.uploadImages .image-upload_ {
  background-color: #f9fafa;
  border: 2px dashed rgba(197, 206, 214, 0.53);
  border-radius: 10px;
  padding: 15px;
}

.uploadImages .imagePreview {
  width: 100%;
  border: 2px dashed rgba(197, 206, 214, 0.53);
  border-radius: 10px;
  max-height: 70px;
  height: 70px;
  overflow: hidden;
  text-align: center;
}

.uploadImages .imagePreview img {
  /* max-height: 70px; */
  height: 100%;
}

.uploadImages .image-upload .loading {
  padding: 45px;
}

.image-area {
  position: relative;
  width: 50%;
  background: #fff;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -5px;
  right: 5px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 500 21px/20px sans-serif;
  background: rgb(255, 0, 0);
  border: 0px solid #fff;
  color: #fff;
}

.image-upload label:hover {
    cursor: pointer;
}
