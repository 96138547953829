.saved-cashout-methods {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.saved-method-option {
  width: 49%;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.wallet-option {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 20px;
  border-radius: 10px;
  padding-block: 10px;
  background-color: #253642;
}

.wallet-option .MuiTypography-root {
  width: 100%;
}

.wallet-option-card {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.wallet-option-content {
  color: white;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.card-info-icon {
  margin-inline-end: 10px;
}

.wallet-option-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-inline-end: 10px;
  font-size: 22px;
}

.card-edit-icon,
.card-delete-icon {
  color: rgb(192, 192, 192);
}

.card-edit-icon:hover {
  color: white;
}

.card-delete-icon:hover {
  color: rgb(200, 43, 43);
}
