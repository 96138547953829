.container {
  display: grid;
  place-items: center;
  margin-top: 2rem;
}

.title {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.link,
.link:hover {
  background-color: var(--clr-secondary);
  color: var(--bg-clr);
  padding: 0.6rem 10rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}
