.create-team h6 {
  margin-bottom: -15px;
  margin-top: 30px;
}

.create-team-form {
  width: 70% !important;
}


.teams-cards {
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.team-card {
  width: 31%;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin: 15px 1.1%;
  position: relative;
}

.team-card h3 {
  color: #253642;
}

.team-card p {
  margin: 25px 0px;
}

.team-card span {
  color: #1292ee;
}

.pen-icon:focus ,
.pen-icon {
  cursor: pointer;
  font-size: 1.5rem;
  display: none;
  color: #2536429a;
  outline: none;
  border: none;
  background-color: transparent;
}

.team-card:hover .pen-icon {
  display: block;
}






/* .shapes-7 {
  width: 50px;
  height: calc(50px*0.866);
  position: relative;
  background:conic-gradient(from 120deg at 50% 64%,#0000, #0047a3 1deg 120deg,#0000 121deg);
  transform-origin: 50% 50%;
  animation: sp7-0 1.5s infinite cubic-bezier(0.3,1,0,1);
}
.shapes-7:before,
.shapes-7:after {
  content:'';
  position: absolute;
  inset:0;
  background:inherit;
  transform-origin: 50% 66%;
  animation: sp7-1 1.5s infinite;
}
.shapes-7:after {
  --s:-1;
}

@keyframes sp7-0 {
   0%,30%      {transform: rotate(0)}
   70%         {transform: rotate(120deg)}
   70.01%,100% {transform: rotate(360deg)}
}

@keyframes sp7-1 {
   0%      {transform: rotate(calc(var(--s,1)*120deg)) translate(0)}
   30%,70% {transform: rotate(calc(var(--s,1)*120deg)) translate(calc(var(--s,1)*-5px),10px)}
   100%    {transform: rotate(calc(var(--s,1)*120deg)) translate(0)}
}

.loading{
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */





