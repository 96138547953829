.request label {
  color: #333;
  margin-bottom: 5px;
}

.request .inputComp {
  margin-bottom: 15px;
}

.request input,
.request select {
  border-radius: 8px;
  height: 50px;
  padding-left: 20px;
}

.request input::placeholder {
  color: #b9bbc2 !important;
}

.create-supplier-option {
  text-align: center;
  font-weight: bold;
  background-color: #eaf2ff;
  padding: 5px 0px;
  border-radius: 3px;
  width: 100%;
}
