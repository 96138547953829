.secNavbarDash .navbar-nav {
    margin-left: 105px;
}

.secNavbarDash {
    background-color: #ffffff;
    border-bottom: 1px solid #cacbcc;
}

.navbar-light .navbar-nav .nav-link,
.secNavbarDash .nav-link {
    color: black;
}

.secNavbarDash .active .nav-link,
.secNavbarDash .active .nav-link:active,
.secNavbarDash .active .nav-link:visited {
    color: #39ac6e;
}

.second-nav-bar {
  max-width: 1280px;
}