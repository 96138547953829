.static-data {
    margin: 0;
    border-radius: 8px;
    height: 50px;
    padding-left: 20px;
    background-color: #c5ced6;
    line-height: 38px;
}

.reconcile-btn {
    margin-top: 30px;
    width: 49%;
}