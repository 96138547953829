.loggin-banner-container {
  width: 60%;
  height: 100%;
  overflow: hidden;
  margin: auto;
}

.login-banner-pic {
  height: 100vh;
  width: auto !important;
  margin: auto !important;
  display: block !important;
}
