.underConstruction {
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 20px 0px;
}

.underConstruction img {
  width: 100%;
  margin-bottom: 30px;
}