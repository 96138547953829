.addCard-configuration {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ant-switch-checked {
  background-color: #4cd964;
}

.billing-switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.billingCashoutDetails {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  margin: 10px auto;
}

.discountDetails {
  color: #828282;
}

.billingPriceBefore {
  color: #bdbdbd;
  text-decoration: line-through;
}

.billingPriceAfter {
  font-weight: bold;
  font-size: large;
}

.priceBeforeAfter {
  align-items: flex-end !important;
}
