/* start nav */
.mainNav {
  max-height: 75.55px;
}

.navbarWeb {
    background-color: #000;
    font-size: 15px;
}

.navbarWeb .green {
    color: #56c288;
}

.navbarWeb .navbar-brand {
    font-size: 20px;
}

.navbarWeb a {
    color: #ffffff;
    transition: 0.3s ease-in-out;
}

.navbarWeb ul a:hover{
    color: #56c288;
}

.flag {
    width: 30px;
    height: 20px;
}