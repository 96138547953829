*:focus,
*:active,
*:focus-visible {
  /* border: none !important; */
  /* border-color: inherit !important; */
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
body {
  font-family: "Almarai" !important;
}

.user-login-card:focus,
.user-login:focus,
li:focus,
a:focus,
.user-login-card:focus-visible,
.user-login:focus-visible,
li:focus-visible,
a:focus-visible,
.user-login-card:focus-within,
.user-login:focus-within,
li:focus-within,
a:focus-within {
  outline: none !important;
  border: none !important;
}

g,
line {
  opacity: 1 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ct-toast-success {
  color: #000;
  background: #d2f0e4;
}

.ct-toast-error {
  color: #000;
  background: #fecfd9;
}
