.form-row {
  align-items: center;
}

.invite-container {
  max-width: 1280px;
  margin: auto;
}

.link-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 350px;
}

.invite-by-link {
  width: 65%;
  height: 100%;
  background-color: #f3fbff;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invite-by-link p {
  width: 45%;
  text-align: center;
  color: #314351;
  margin: 10px auto 20px auto;
}

.invite-by-link img {
  width: 100%;
}

.inviteActions {
  margin-bottom: 30px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btnURL {
  background-color: #1292ee;
  color: white;
  width: 48%;
}

.btnSlack {
  background-color: #000;
  color: white;
  width: 48%;
}

.btnSlack img {
  width: 20px;
}

.joined-by-link {
  width: 33%;
  background-color: #fff;
  border-radius: 20px;
  height: 100%;
  overflow-y: scroll;
}

.joined-by-link h3 {
  padding: 10px;
}

.user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(197, 206, 214, 0.49);
}

.joined-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.joined-user img {
  width: 45px;
  border-radius: 50%;
}

.joined-user-info {
  margin: auto 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.joined-user-info h5 {
  font-weight: 500;
  margin-bottom: 2px;
}

.joined-user-info p {
  color: #314351;
  margin-bottom: 0px;
  font-size: 14px;
}

.userActions {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.forms-side {
  width: 65%;
}

.element30 {
  width: 32%;
}

.element60 {
  width: 66%;
}

.form-row select {
  height: 38px !important;
}

.add-team {
  margin-bottom: 0px;
  color: rgb(18, 146, 238);
  cursor: pointer;
}

.remove-member {
  top: 10px;
  right: 10px;
  width: fit-content;
}

.ant-drawer-content-wrapper {
  width: 25% !important;
}

.emailInvitation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.invited-side {
  width: 30%;
  
}

.invitees-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  font-size: 1rem;
}