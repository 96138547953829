.pending-expense .item .request .request-user-image {
  width: 55px;
  overflow: hidden;
  margin: auto;
}

.pending-expense .item .request-user-image img {
  width: 100%;
}

.pending-expense .item .request .request-image {
  overflow: hidden;
  width: auto;
  max-height: 50px;
  margin-left: 10px;
}

.pending-expense .item .request .request-image img {
  max-height: 50px;
  cursor: pointer;
}

.pending-expense .item .request .request-images {
  margin-left: 30px;
  vertical-align: middle;
}

.request-user-image {
  position: relative;
  z-index: 100;
}

.supplier-logo {
  background-color: #d6edff;
}

.floating-avatar {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.modal-image {
  width: 100%;
  height: auto !important;
  max-height: 500px;

  text-align: center !important;
}
