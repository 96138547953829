.container {
  padding: 16px;
}

.input {
  width: 2ch;
  padding: 0px 8px;
  border-radius: 8px;
  font-size: 40px;
  text-align: center;
  margin-right: 12px;
  /* border: 1px solid white; */
  text-transform: uppercase;
  border-radius: 6px;
    border: 1px solid lightgrey;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px 0px;
}