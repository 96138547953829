.loginForm-wrapper {
  width: 40%;
  height: 100%;
  background: white !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginForm {
  padding-inline: 30px;
  width: 100%;
  max-width: calc(1600px * 0.4);
  height: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.login-logo {
  margin-bottom: 20px;
  width: 90%;
}

.loginform-title {
  font-size: 1.8rem !important;
  text-align: start !important;
}

.forget-pass-link {
  display: inline-block;
  width: 100%;
  text-align: end;
  color: #56c288;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 30px;
  margin-top: -20px;
}

.login-btn {
  width: 100%;
  margin-bottom: 50px;
}

.login-register {
  font-weight: 600;
  font-size: 1.3rem;
}

.login-register-link {
  color: #56c288;
}

.login-register-link:hover,
.forget-pass-link:hover {
  color: #56c288;
  text-decoration: underline;
}
