.list {
  padding: 0;
  list-style-type: none;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.item {
  flex-basis: calc(33% - (30px * 2) / 3);
  background: var(--bg-clr);
  padding: 1em;
  border-radius: 8px;
  box-shadow: 10px 0 10px rgpa(0, 0, 0, 0.6);
}

.item_active,
.item_deactive {
  padding: 2px 8px;
  border-radius: 15px;
  color: var(--bg-clr);
}

.item_active {
  background-color: var(--clr-primary);
}
.item_deactive {
  background-color: var(--clr-danger);
}

.title {
  margin-top: 10px;
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: 600;
  color: var(--clr-secondary);
}

.description {
  font-size: 18px;
  color: var(--clr-secondary);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
