.wallet-banner {
  max-width: 1280px;
  margin: 20px auto;
  padding: 30px 30px;
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.02);
  background-image: linear-gradient(222deg, #314351, #1f2d38);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wallet-info {
  color: #fff;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.wallet-info h1 {
  padding-top: 30px;
  padding-bottom: 60px;
  color: inherit;
}

.wallet-info h4,
.wallet-info h5,
.wallet-info h6 {
  color: inherit;
}

.transaction {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.sub-transaction {
  width: 42%;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: inherit;
}

.sub-transaction img {
  width: 50px;
  margin: 15px;
}

.wallet-card {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}

.wallet-card img {
  width: 100%;
}

.wallet-card-info {
  position: absolute;
  top: 25px;
  bottom: 10px;
  right: 0;
  left: 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.companies {
  display: flex;
  justify-content: space-between;
  color: #253642;
  font-weight: bold;
}

.credits p {
  margin-bottom: 10px;
  color: #253642;
  font-weight: bold;
}

.credits h1 {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 0px;
}

.lock {
  font-size: x-large;
  margin-bottom: 5px;
}

.banking {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  border: solid 0.5px #c5ced6;
  background-color: #ffffff;
}

.banking-head {
  background-color: #253642;
  padding: 15px 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #ffffff;
}

.bank-logo {
  width: 60px;
  border-radius: 5px;
}

.banking-spacer {
  width: 3%;
}

.banking-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 15px 25px;
}

.banking-info div {
  width: 50%;
}

.banking-info h5 {
  color: #405261;
}

.wallet-upload-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-upload-modal button {
  width: 70%;
}

.file-upload-wallet {
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 4px;
  border: solid 0.8px rgba(197, 206, 214, 0.53);
  cursor: pointer;
  background-color: #f9fafa;
}

.file-upload-wallet img {
  margin: 15px;
}

/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/

.rounded-lg {
  border-radius: 20px !important;
}

.nav-pills .nav-link {
  color: #555 !important;
}

.nav-pills .nav-link.active {
  color: #fff !important;
}
.nav-item {
  margin-bottom: 0px !important;
}

.file-upload input[type="file"] {
  display: none;
}

/* ==========================================
* COMPANY WALLET OPTIONS AND CONTENT
* ==========================================*/

.company-wallet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 20px;
}

.charge-options {
  width: 45%;
}

.option-label {
  margin-bottom: 30px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.option-img {
  width: 40px;
  margin: 5px;
  border-radius: 5px;
}

.option-dialog {
  margin-bottom: 30px;
  margin-top: -30px;
  border-radius: 3px;
  border: solid 0.5px #c5ced6;
  background-color: #f9fafa;
  padding: 20px;
}

.option-dialog h6 {
  color: #1d2b36;
}

.option-dialog p {
  color: #314351;
}

.option-dialog button {
  border-radius: 2px;
  background-color: #1292ee;
  text-align: center;
  color: #ffffff;
  width: 100%;
}

.option-content {
  width: 45%;
}

.secure {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.reciept-amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: auto;
}

.reciept-amount p {
  margin-bottom: 0px;
}

/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/

.rounded-lg {
  border-radius: 1rem;
}

.custom-file-label.rounded-pill {
  border-radius: 50rem;
}

.custom-file-label.rounded-pill::after {
  border-radius: 0 50rem 50rem 0;
}

.card {
  border: none;
  border-radius: 10px;
  margin-top: 2%;
  width: 100%;
}

.fa-ellipsis-v {
  font-size: 10px;
  color: #ffffff;
  margin-top: 6px;
  cursor: pointer;
}

.text-dark {
  font-weight: bold;
  margin-top: 8px;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.card-bottom {
  background-image: linear-gradient(147deg, #d8efff -13%, #b7dcf6 135%);
  border-radius: 6px;
  width: 100%;
  height: 180px;
}

.flex-column {
  color: #000000;
  font-size: 13px;
  font-weight: bolder;
}

.flex-column p {
  top: 200px;
  letter-spacing: 1px;
  color: #6b6b6b;

  font-size: 18px;
}

.flex-column h1 {
  letter-spacing: 1px;
  color: #252525;

  font-weight: bolder;
}

.btn-secondary {
  height: 40px !important;
  margin-top: 3px;
}

.btn-secondary:focus {
  box-shadow: none;
}

.nav-item_.active {
  background-color: darkgreen !important;
  color: #252525;
}

.nav .nav-item > a:active,
.nav .nav-item > a:focus {
  color: #fff !important; /* <----- change your color here*/
  background-color: #009b54 !important;
}

.nav .nav-item > a {
  color: rgb(82, 82, 82) !important; /* <----- change your color here*/
  background-color: #b8ebd4 !important;
}

.nav .nav-item > a:hover {
  color: #009b54 !important;
  border: 1px solid #009b54 !important;
  background: none !important;
}
