.my-wallet-container,
.cashout-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.my-wallet-desc {
  width: 55%;
}

.my-wallet-options {
  width: 42%;
}

.cashout-column {
  width: 32%;
}

.wallet ul {
  padding-left: 10px;
}

.wallet ul li {
  margin-bottom: 30px;
  line-height: 15px;
  list-style: none;
}

.wallet ul li img {
  margin-right: 15px;
  display: inline-block;
}

.wallet ul li span {
  display: inline-block;
  width: 80%;
  line-height: 20px;
}

.wallet ul li:nth-of-type(2) span {
  position: relative;
  top: 0px;
}
.max {
  color: grey;
  size: 3px;
  display: inline;
}

.wallet ul li:nth-of-type(2) {
  margin-top: 0px;
  margin-bottom: 20px;
}

.wallet .wallet-image img {
  border-radius: 10px;
  width: 100%;
}

.wallet {
  min-height: 520px;
}

.wallet h3,
.wallet h4 {
  font-weight: bold;
}

.wallet a {
  width: 50%;
  height: 50px;
  line-height: 35px;
  margin: auto;
  position: relative;
}

.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
}
.inputGroup label {
  padding: 12px 30px;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
}
.inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.inputGroup label:after {
  width: 32px;
  height: 32px;
  content: "";
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}
.inputGroup input:checked ~ label {
  color: rgb(0, 0, 0);
}
.inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.inputGroup input:checked ~ label:after {
  background-image: linear-gradient(147deg, #d8efff -13%, #b7dcf6 135%);
}
.inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}
.form {
  padding: 0 16px;
  max-width: 550px;
  margin: 50px auto 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}
body {
  background-color: #ffffff;
  font-family: "Fira Sans", sans-serif;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
}
code {
  background-color: #9aa3ac;
  padding: 0 8px;
}

.cash-method {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
}

.cashout-label {
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.cashout-label .cash-method img {
  width: 40px;
  height: 40px;
  margin: 5px;
  border-radius: 5px;
}

.wallet-confirm {
  width: 40%;
  left: 30%;
}

.confirm-modal h3 {
  text-align: center;
  margin: 40px auto;
}

.confirm-modal .confirm-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.confirm-modal .confirm-buttons button {
  width: 30%;
}

.wallet-trans {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.soon-modal {
  padding: 20px;
}

.soon-modal h3 {
  text-align: center;
}

.soon-modal button {
  width: 40%;
  margin-left: 30%;
}

.MuiTypography-body1 {
  font-family: "Almarai" !important;
}

.cellSTart {
  text-align: start !important;
}
