.container {
  padding: 1em 3.5em;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
}

.links_wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.title {
  font-weight: 600;
}

.links_wrapper > * {
  width: 19em;
  padding: 0.6em;
  text-align: center;
  border-radius: 0.5em;
  font-weight: 500;
}

.link_outline,
.link_outline:hover,
.link_outline:focus {
  color: var(--clr-info);
  border: 1px solid var(--clr-info) !important;
}
.link_contained,
.link_contained:hover {
  background-color: var(--clr-secondary);
  color: var(--bg-clr);
}
