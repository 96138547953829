.invitation {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.invitation-side {
  background-color: #000;
  color: #fff;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../../assets/images/orbits.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: -30px;
}

.invitation-side img {
  width: 70%;
  margin: 60px auto;
}

.invitation-side h3 {
  width: 50%;
  text-align: center;
  font-weight: bold;
  word-spacing: 1px;
  letter-spacing: 2px;
}

.invitation-content {
  width: 80%;
}

.invitation-content h5 {
  width: 50%;
}

.invitation-content form {
  width: 70%;
}

.form-row {
  align-items: flex-start;
}

.row-element p {
  color: #e32424;
}
