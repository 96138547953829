.uploadImage .imagePreview {
    display: block;
    padding: 20px 30px;
    border: 1px solid #ced4da;
    text-align: center;
    cursor: default;
    border-radius: 5px;
    min-height: 200px;
}

.uploadImage .imagePreview img {
    width: 100%;
    max-height: 250px;
}