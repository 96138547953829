.help-menu {
  max-width: 450px;
  background-color: #edf6fa;
  text-align: center;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  border: solid 0.5px #c5ced6;
}

.help-menu img {
  width: 70%;
  margin: auto;
}

.help-menu h3 {
  font-weight: bold;
  color: #314351;
}

.help-menu h5 {
  color: #525f6a;
  width: 90%;
  margin: 10px auto;
}

.help-menu a {
  color: white !important;
  font-weight: bold;
  border-radius: 30px;
  background-color: #1292ee;
}