:root {
  --clr-primary: #56c288;
  --clr-secondary: #405261;
  --clr-info: #0073a7;
  --bg-clr: #fff;
  --body-clr: #e5e5e5;
  --clr-danger: #ef4444;
  scroll-behavior: smooth;
}

.container {
  font-family: "inter" !important;
  direction: ltr !important;
}
/* body[dir="rtl"] {
  text-align: right !important;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* Global styles for Modal */
.modal {
  display: grid;
  place-items: center;
  font-family: "inter" !important;
  direction: ltr !important;
}
.modal_container {
  width: 650px;
  background: #fff;
  border-radius: 20px;
  padding: 2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.title {
  font-weight: 700;
  font-size: 18px;
}

.icon {
  cursor: pointer;
}

.content {
  font-size: 18px;
  margin: 2rem 0;
}

.footer {
  display: flex;
  margin-top: 1.5rem;
}

.footer > * {
  flex-grow: 1;
}

.btn,
.btn:hover {
  padding: 12px 0;
  border: none;
  outline: none !important;
  cursor: pointer;
}

.btn_contained,
.btn_contained:hover {
  background: #ef4444;
  border-radius: 8px;
  color: #fff !important;
}

.btn_contained_success,
.btn_contained_success:hover {
  background: var(--clr-primary);
  border-radius: 8px;
  color: #fff !important;
  height: none;
}

.btn_outline {
  background: none;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.label {
  font-weight: 600;
  font-size: 14px;
}

.input {
  border: 1px solid #d8dae6;
  border-radius: 4px;
  padding: 10px;
  font-size: 18px;
  outline: none;
}

.input::placeholder {
  color: #d8dae6;
}

.error {
  color: var(--danger);
  margin-top: 0.5em;
}

.input_box {
  display: flex;
  flex-direction: column;
}

.input_box input,
select {
  padding: 1em;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 4px;
}

.input_box input:disabled {
  padding: 1em;
  background: #d8dae5;
  outline: none;
}

.phone-box input {
  padding: 0;
  border: none;
}

.phone-box {
  border: 1px solid #d8dae6;
  border-radius: 4px;
  padding: 10px;
  font-size: 18px;
  outline: none;
}

/* End Modal styles */
