@media (max-width: 480px) {
  body {
    width: 100%;
    height: auto;
  }

  .container-fluid {
    padding: 0px;
  }

  .ant-drawer-body {
    padding: 0px !important;
  }

  .row {
    margin-inline: 0px !important;
  }

  .loginForm-wrapper {
    width: 100%;
  }

  .loggin-banner-container {
    display: none;
  }

  /*------------------- HOME PAGE ---------------------*/

  .mainNav {
    margin: auto;
  }

  .logos-area {
    flex-wrap: wrap;
  }

  .trusted-logos {
    margin-bottom: 20px;
  }

  .home-img {
    display: none;
  }

  .welcome {
    background-position: 50px center;
    background-size: 150%;
    height: fit-content;
  }

  /*------------------- LOGIN ---------------------*/

  .login {
    width: 100%;
    height: fit-content;
    min-height: 110vh;
    /* padding-top: 20px; */
  }

  .login-container {
    width: fit-content;
  }

  .login .holder {
    flex-direction: column-reverse;
    padding-right: 0px;
  }

  .login-form {
    width: 100%;
    padding: 5px 20px;
    height: fit-content;
  }

  .login-banner {
    width: 100%;
    height: 500px;
  }

  .login .border-b {
    display: none;
  }

  .login-logo {
    width: 70% !important;
    margin-bottom: 30px;
  }

  .loginform-title {
    font-size: 1.8rem;
    margin-bottom: 40px;
  }

  .forget-pass-link {
    margin-top: -20px;
    font-size: 1rem;
  }

  .login-btn {
    margin-bottom: 30px;
  }

  /*------------------- MAIN NAVBAR ---------------------*/

  .mobile-menu {
    display: block;
  }

  .nav-menus,
  .right-nav {
    display: none;
  }

  .ant-drawer-content-wrapper {
    width: 80% !important;
  }

  /*------------------- USER WALLET ---------------------*/

  .my-wallet-container,
  .cashout-container {
    flex-direction: column;
  }

  .my-wallet-desc {
    width: 100%;
  }

  .my-wallet-options {
    width: 100%;
  }

  .cashout-column {
    width: 100%;
    margin-bottom: 30px;
  }

  /*------------------- COMPANY WALLET ---------------------*/

  .wallet-banner,
  .transaction {
    flex-direction: column;
  }

  .wallet-banner {
    padding: 15px 15px;
    margin: 20px 10px;
  }

  .wallet-info,
  .wallet-card,
  .sub-transaction {
    width: 100%;
  }

  .wallet-info h1 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .wallet-card-info {
    padding: 10px;
  }

  .credits h1 {
    font-size: 40px;
  }

  .company-wallet {
    flex-direction: column;
    padding: 30px 20px;
  }

  .charge-options {
    width: 100%;
  }

  .option-content {
    width: 100%;
  }

  /*------------------- INSIGHTS ---------------------*/

  .expense-chart-head {
    flex-direction: column;
    padding: 10px 0px !important;
  }

  .expense-chart-head button {
    font-size: 0.8rem;
  }

  .date-export {
    justify-content: space-between;
  }

  /*------------------- TEAM REQUESTS ---------------------*/

  .dashboard-pages .row:first-child {
    margin: auto;
  }

  .dashboard-pages nav {
    width: 100%;
  }

  .mainPage {
    margin: 20px 0px;
  }

  .here {
    display: none;
  }

  .expenseFilter {
    position: relative;
    overflow-y: scroll;
  }

  .filter .tab {
    margin: 10px 10px;
  }

  .pending-expense .item .item-requests .request {
    padding: 15px 0px;
  }

  /*------------------- CATEGORIES ---------------------*/

  .categoriesTable {
    width: 100%;
  }

  /*------------------- INDEBTEDNESS ---------------------*/

  .exportBtn {
    display: none;
  }

  .page-content {
    padding: 0px;
  }

  .indebtedness {
    padding-top: 10px;
  }

  /*------------------- RECONCILATION MODAL ---------------------*/

  .note-3 {
    width: 80%;
  }

  .note-option {
    width: 90%;
  }

  .reconsileResBtn {
    width: 80%;
    margin-left: 10%;
  }

  /*------------------- MY REQUESTS ---------------------*/

  .filter-nav h6 {
    width: max-content;
  }

  .requests-head {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .request-review {
    flex-direction: column;
  }

  .req-doc {
    width: 100%;
    height: auto;
  }

  /*------------------- SUPPLIER ---------------------*/

  .supplier-req-rev,
  .supplier-user-actions {
    width: 100%;
  }

  /*------------------- NEW REQUEST ---------------------*/

  .request-inadvance {
    flex-direction: column;
  }

  .reconcile-upload,
  .inadvance-form {
    width: 100%;
  }

  .spliter {
    margin: 20px 5px;
  }

  /*------------------- TEAM MEMBERs ---------------------*/

  .team-controls {
    display: none;
  }

  /*------------------- INVITE TEAM MEMBER ---------------------*/

  .link-container,
  .emailInvitation {
    flex-direction: column;
    height: auto;
  }

  .invite-by-link,
  .inviteActions,
  .forms-side,
  .invited-side {
    width: 100%;
  }

  .joined-by-link {
    width: 100%;
    height: 300px;
  }

  /*------------------- CREATE NEW TEAM ---------------------*/

  .create-team-form,
  .element60,
  .element30 {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .teams-cards {
    justify-content: space-between;
  }

  .team-card {
    width: 49%;
    margin-inline: 0px;
  }

  /*------------------- USER SETTINGS ---------------------*/

  .profile-settings {
    width: 90%;
    flex-direction: column;
  }

  .profilePicture-side,
  .profile-info {
    width: 100%;
  }

  .notification-settings,
  .account-settings {
    width: 90%;
  }

  /*------------------- BILLING PLAN ---------------------*/

  .billing-plan h4 {
    font-size: 0.8rem;
    text-align: start;
    width: 100%;
  }

  .billing-plan h1 {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .current-membership {
    flex-direction: column;
  }

  .membership-dateils {
    width: 100%;
    text-align: start;
  }

  .membership-dateils button {
    width: 100% !important;
  }

  .membership-price {
    width: 100%;
    text-align: start;
    margin-bottom: 0px;
  }

  .plan-control {
    flex-direction: column;
  }

  .plan-control-element {
    width: 100%;
    margin-bottom: 20px;
  }

  .plan-cards-container {
    flex-direction: column;
  }

  .plan-card {
    width: 100%;
  }

  /*------------------- UPGRADE PLAN ---------------------*/

  .upgrade-container {
    flex-direction: column;
  }

  .payment-details,
  .plans-to-upgrade {
    width: 100%;
    margin-top: 10px;
  }

  /*------------------- GETTING STARTED ---------------------*/

  .page {
    padding-inline: 10px;
  }

  .gettingStarted .form-row,
  .company .form-row {
    flex-direction: column;
  }

  .gettingStarted .row-element,
  .company .row-element {
    width: 100%;
    margin-bottom: 10px;
  }

  .adminRoles .first-row h4,
  .adminRoles .first-row p {
    text-align: center !important;
    margin-inline: auto;
  }

  .adminRoles .second-row button {
    margin-top: 20px;
  }

  .setupFinished h1 {
    font-size: x-large;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  /*------------------- MAIN NAVBAR ---------------------*/

  .mobile-menu {
    display: block;
  }

  .nav-menus,
  .right-nav {
    display: none;
  }

  .ant-drawer-content-wrapper {
    width: 60% !important;
  }

  .loginForm-wrapper {
    width: 100%;
  }

  .loggin-banner-container {
    display: none;
  }

  /*------------------- TEAM REQUESTS ---------------------*/

  .dashboard-pages .row:first-child {
    margin: auto;
  }

  .dashboard-pages nav {
    width: 100%;
  }

  .mainPage {
    margin: 20px 0px;
  }

  .here {
    display: none;
  }

  .expenseFilter {
    position: relative;
    overflow-y: scroll;
  }

  .filter .tab {
    margin: 10px 10px;
  }

  .pending-expense .item .item-requests .request {
    padding: 15px 0px;
  }

  /*------------------- MY REQUESTS ---------------------*/

  .filter-nav h6 {
    width: max-content;
  }

  .requests-head {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .request-review {
    flex-direction: column;
  }

  .req-doc {
    width: 100%;
    height: auto;
  }

  /*------------------- USER WALLET ---------------------*/

  .my-wallet-container,
  .cashout-container {
    flex-direction: column;
  }

  .my-wallet-desc {
    width: 100%;
  }

  .my-wallet-options {
    width: 100%;
  }

  .cashout-column {
    width: 100%;
    margin-bottom: 30px;
  }

  /*------------------- COMPANY WALLET ---------------------*/

  .wallet-banner,
  .transaction {
    flex-direction: column;
  }

  .wallet-banner {
    padding: 15px 15px;
    margin: 20px 10px;
  }

  .wallet-info,
  .wallet-card,
  .sub-transaction {
    width: 100%;
  }

  .wallet-info h1 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .wallet-card-info {
    padding: 10px;
  }

  .credits h1 {
    font-size: 40px;
  }

  .company-wallet {
    flex-direction: column;
    padding: 30px 20px;
  }

  .charge-options {
    width: 100%;
  }

  .option-content {
    width: 100%;
  }

  /*------------------- TEAM MEMBERs ---------------------*/

  .team-controls {
    display: none;
  }

  /*------------------- GETTING STARTED ---------------------*/

  .page {
    padding-inline: 10px;
  }

  .gettingStarted .form-row {
    flex-direction: column;
  }

  .gettingStarted .row-element {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  /*------------------- MAIN NAVBAR ---------------------*/

  .mobile-menu {
    display: block;
  }

  .nav-menus,
  .right-nav {
    display: none;
  }

  .ant-drawer-content-wrapper {
    width: 40% !important;
  }

  /*------------------- USER WALLET ---------------------*/

  .my-wallet-container,
  .cashout-container {
    flex-direction: column;
  }

  .my-wallet-desc {
    width: 100%;
  }

  .my-wallet-options {
    width: 100%;
  }

  .cashout-column {
    width: 100%;
    margin-bottom: 30px;
  }

  /*------------------- COMPANY WALLET ---------------------*/

  .wallet-banner,
  .transaction {
    flex-direction: column;
  }

  .wallet-banner {
    padding: 15px 15px;
    margin: 20px 10px;
  }

  .wallet-info,
  .wallet-card,
  .sub-transaction {
    width: 100%;
  }

  .wallet-info h1 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .wallet-card-info {
    padding: 40px;
  }

  .credits h1 {
    font-size: 40px;
  }

  /*------------------- TEAM MEMBERs ---------------------*/

  .team-controls {
    display: none;
  }

  /*------------------- GETTING STARTED ---------------------*/

  .page {
    padding-inline: 20px;
  }

  .side-nav > p {
    font-size: x-large;
  }

  /* .gettingStarted .form-row {
    flex-direction: column;
  }

  .gettingStarted .row-element {
    width: 100%;
    margin-bottom: 10px;
  } */
}

@media (min-width: 1024px) and (max-width: 1080px) {
  /*------------------- MAIN NAVBAR ---------------------*/

  .left-nav {
    font-size: 15px;
  }

  /*------------------- COMPANY WALLET ---------------------*/

  .wallet-banner {
    padding: 15px 15px;
    margin: 20px 10px;
  }

  .wallet-card-info {
    top: 90px;
    padding: 10px;
  }

  /*------------------- TEAM MEMBERs ---------------------*/

  .team-controls {
    display: none;
  }

  /*------------------- GETTING STARTED ---------------------*/

  .side-nav > p {
    font-size: x-large;
  }
}
